@import '@/assets/css/config';
@font-face {
    font-family: 'ZH';
    src: url("../assets/font/DIN-BlackItalicAlt.otf");
}
@font-face {
    font-family: 'CH';
    src: url("../assets/font/WenYue-XinQingNianTi-NC-W8.otf");
}
.index{
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  min-height: 100%;
  padding-top: 1.6rem;
  padding-bottom: 2rem;
  background-color: $bg-color;
  .fm-li{
    margin: 0 auto;
    margin-top: 0.826666rem;
    padding-bottom: 0.533333rem;
    width: 8.56rem;
    
  }
  .head{
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content:flex-start;
    align-items:center;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.6rem;
    background-color: #FFFFFF;
    transform: translateY(-100%);
    transition: transform .3s .3s;
    &.active{
      transform: translateY(0);
    }
    .logo{
      margin-left: 0.266666rem;
      width: 1.546666rem;
      height: 1.6rem;
    }
    .name{
      margin-left: 0.293333rem;
      .atyz{
        font-size: 0.426666rem;
        color: #151515;
        line-height: 0.586666rem;
        font-weight: bold;
      }
      p{
        line-height: 0.533333rem;
        font-size: 0.373333rem;
        color: #ACB0BE;
      }
    }
    .download{
      margin-left: 2rem;
      width:2.56rem;
      height:0.8rem;
      background:linear-gradient(161deg,rgba(255,59,100,1) 0%,rgba(255,26,26,1) 100%);
      border-radius:0.106666rem;
      line-height: 0.8rem;
      color: #FFFFFF;
      text-align: center;
      font-size: 0.4rem;
      font-weight:600;
      span{
        font-family: 'ZH';
      }
    }
  }
}